<template>
  <div>
    <header>
      <div class="header-new">
        <div class="img-logo">
          <!-- <img
            src="https://f.pco.bet/logo/f9ba71f6-39e7-6224-36de-a2a15d016177.gif"
            alt=""
          > -->
        </div>

        <div class="balance-txt">
          <div class="balance-txt__text">
            <span><i class="fas fa-wallet" /></span>
            <span class="balance-num">{{ Commas(MainWallet) }} ฿</span>
          </div>

          <div
            class="nav-side cursor-pointer"
            @click="showModal"
          >
            <i class="fas fa-ellipsis-v" />
            <i class="fas fa-ellipsis-v" />
            <i class="fas fa-ellipsis-v" />
          </div>
        </div>
      </div>
    </header>

    <b-modal
      ref="my-modal"
      hide-footer
      hide-header
      centered
      size="sm"
    >
      <div class="hd-h2 text-center">
        <h3>เมนูหลัก</h3>
      </div>

      <div class="-modal-profile-mobile">
        <div class="x-profile-image ng-star-inserted text-center">
          <img
            src="@/assets/newImg/profile.jpg"
            alt="customer image"
            height="60px"
            class="-profile-image rounded-circle border border-2"
          >
        </div>

        <div class="-balance-containers ng-star-inserted text-center">
          <div class="-text-username">
            xxxx xxxxx
          </div>
          <div class="-user-balance js-user-balance f-sm-6 f-7">
            <div class="-inner-box-wrapper">
              <img
                src="@/assets/newImg/ic-coin.png"
                alt="customer image"
                width="26"
                height="21"
                class="img-fluid -ic-coin"
              ><span id="customer-balance">
                {{ Commas(MainWallet) }} ฿</span>
            </div>
          </div>
        </div>

        <ul class="navbar-navs">
          <li class="nav-item ng-star-inserted">
            <router-link
              :to="{ name: 'tanghuay-home'}"
            >
              <button
                type="button"
                class="nav-links js-close-account-sidebar"
                tabindex="0"
              >
                <img
                  src="@/assets/newImg/home.png"
                  alt="ic-menu-user"
                  class="-icon-image"
                  height="35"
                ><span
                  class="-text-menu"
                > หน้าหลัก </span>
              </button>
            </router-link>
          </li>

          <li class="nav-item ng-star-inserted">
            <router-link
              :to="{ name: 'tanghuay-deposit'}"
            >
              <button
                type="button"
                class="nav-links js-close-account-sidebar"
                tabindex="0"
              >
                <img
                  src="@/assets/newImg/ic-mobile-right-1.webp"
                  alt="ic-menu-user"
                  class="-icon-image"
                  height="35"
                ><span
                  class="-text-menu"
                > เติมเงิน </span>
              </button>
            </router-link>
          </li>

          <li class="nav-item ng-star-inserted">
            <router-link
              :to="{ name: 'tanghuay-withdraw'}"
            >
              <button
                type="button"
                class="nav-links js-close-account-sidebar"
                tabindex="0"
              >
                <img
                  src="@/assets/newImg/download.webp"
                  alt="ic-menu-user"
                  class="-icon-image"
                  height="35"
                ><span
                  class="-text-menu"
                > ถอนเงิน </span>
              </button>
            </router-link>
          </li>

          <li class="nav-item ng-star-inserted">
            <router-link
              :to="{ name: 'tanghuay-round'}"
            >
              <button
                type="button"
                class="nav-links js-close-account-sidebar"
                tabindex="0"
              >
                <img
                  src="@/assets/newImg/we.webp"
                  alt="ic-menu-user"
                  class="-icon-image"
                  height="35"
                ><span
                  class="-text-menu"
                > แทงหวย </span>
              </button>
            </router-link>
          </li>

          <li class="nav-item ng-star-inserted">
            <router-link
              :to="{ name: 'tanghuay-list'}"
            >
              <button
                type="button"
                class="nav-links js-close-account-sidebar"
                tabindex="0"
              >
                <img
                  src="@/assets/newImg/doc.png"
                  alt="ic-menu-user"
                  class="-icon-image"
                  height="35"
                ><span
                  class="-text-menu"
                > โพยของฉัน </span>
              </button>
            </router-link>
          </li>
        </ul>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BModal, VBToggle } from 'bootstrap-vue'

export default {
  components: {
    BModal,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      Interval: null,
      MainWallet: 0,
      UserData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  beforeDestroy() {
    clearInterval(this.Interval)
  },
  mounted() {
    this.ShowWallet()
    this.Interval = setInterval(() => {
      this.ShowWallet()
    }, 2000)
  },
  methods: {
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    toggleModal() {
      this.$refs['my-modal'].toggle('#toggle-btn')
    },
    async ShowWallet() {
      try {
        const params = {
          UserToken: localStorage.getItem('UserToken'),
        }
        const { data: response } = await this.$http.get(
          'https://api.rmtlotto.com/api/seamless/wallet/show', { params },
        )
        if (response && response.success === true) {
          const Main = response.WalletData
          this.MainWallet = Main.balance
          this.$store.state.appConfig.UserData = { Balance: Main.balance, Discount: response.discount }
        } else {
          this.MainWallet = 0
          this.$store.state.appConfig.UserData = { Balance: 0, Discount: 0 }
        }
      } catch (err) {
        console.log(err)
      }
    },
    Commas(x) {
      if (Math.round(x) !== x && x > 0) {
        const Num = Number(x)
        const y = Num.toFixed(2)
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    // async ShowWallet() {
    //   this.MainWallet = this.$store.state.appConfig.UserData.Balance
    //   this.discount = this.$store.state.appConfig.UserData.Discount
    // },
  },

}
</script>
